import React, { useEffect, useState } from 'react';
import ChatModule, { getBotConfigs } from 'chat-module';
import { useSearchParams } from 'react-router-dom';
import useScreen from './hooks/useMobileScreen';
import { getCurrentRouteUrl, getUserIPAddress } from './utils/common';
import { CHAT_AGENT_IS_NOT_ACTIVE } from './constant/common';

export interface ChatConfigProps {
  botType: string;
  configurations: {
    botWelcomeMessage: string;
    suggestedPrompts: string;
    showLeadForm: number;
    leadFormFields: string;
    autoOpenChat: number;
    removeBranding: number;
    interfaceConfigurations: {
      font: string;
      businessLogoUrl: string;
      messageSettings: {
        bot: {
          bgColor: string;
          fontColor: string;
        };
        other: {
          bgColor: string;
          fontColor: string;
        };
        prompt: {
          bgColor: string;
          fontColor: string;
        };
      };
      chatBotBubbleIconUrl: string;
      chatBotIcon: string;
    };
  };
}

const ChatLink = () => {
  const { isMobileScreen, isTablet } = useScreen();
  const [chatbotConfig, setChatbotConfig] = useState<ChatConfigProps>();
  const [searchParams] = useSearchParams();
  const agentId = searchParams.get('agentId');
  const interfaceType = searchParams.get('interfaceType');
  const width = isMobileScreen || isTablet ? '100%' : interfaceType === 'bubble' ? '440px' : '880px';
  const height = isMobileScreen || interfaceType === 'link' ? '100%' : interfaceType === 'iframe' ? '668px' : '607px';
  const [agentIsActive, setAgentIsActive] = useState<boolean>(true);
  const [errors, setError] = useState([]);

  const clearingLocalStorageData = () => {
    localStorage.removeItem('agentId');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('chatTokens');
    localStorage.removeItem('chatIds');
    localStorage.removeItem('chatAvtarImage');
    localStorage.removeItem('businessLogoUrl');
    localStorage.removeItem('botType');
    localStorage.removeItem('botName');
    localStorage.removeItem('leadFormData');
  };

  useEffect(() => {
    let paramErros = [];
    if (!agentId) {
      paramErros.push('Agent ID is required');
    }
    if (!interfaceType) {
      paramErros.push('Interface Type is required');
    }
    setError(paramErros);
  }, [agentId, interfaceType]);

  useEffect(() => {
    const prevAgentId = localStorage.getItem('agentId');
    if (agentId !== prevAgentId) {
      clearingLocalStorageData();
      localStorage.setItem('agentId', agentId);
    } else {
      localStorage.setItem('agentId', agentId);
    }

    getUserIPAddress().then((IP) => {
      const body = {
        ipAddress: IP,
        location: getCurrentRouteUrl(),
      };

      getBotConfigs(process.env.REACT_APP_BASE_URL, agentId, body)?.then((response) => {
        if (response?.response?.data?.error?.message === CHAT_AGENT_IS_NOT_ACTIVE) {
          setAgentIsActive(false);
        } else if (response?.response?.data?.error) {
          setAgentIsActive(false);
        } else {
          localStorage.setItem('sessionId', response?.data?.data?.sessionId);
          localStorage.setItem('botType', response?.data?.data?.botType);
          setChatbotConfig(response?.data?.data);
        }
      });
    });
  }, [agentId]);

  if (errors.length > 0) {
    return (
      <div>
        <h1>Errors</h1>
        {errors.map((error, index) => (
          <p key={index}>{error}</p>
        ))}
      </div>
    );
  } else if (!agentIsActive) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '140px',
          }}>
          <img src={'./assets/not_found.svg'} alt="Not Found" />
          <div
            style={{
              marginTop: '56px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              flexDirection: 'column',
            }}>
            <span
              style={{
                color: '#475467',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
                letterSpacing: '-0.32px',
              }}>
              Agent not available
            </span>
            <span
              style={{
                color: '#667085',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '150%',
                letterSpacing: '-0.16px',
              }}>
              The agent you're trying to access is not available.
            </span>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          id={'ciara-chat-link-1'}
          style={{
            width: '100%',
            height: interfaceType === 'bubble' ? height : '100vh',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div
            id={'ciara-chat-link-2'}
            style={{
              width: width,
              height: height,
              paddingTop: interfaceType === 'iframe' ? '5px' : '0px',
            }}>
            <ChatModule
              agentId={agentId}
              interfaceType={interfaceType}
              baseUrl={process.env.REACT_APP_BASE_URL}
              logoUrl={process.env.REACT_APP_DEFAULT_LOGO_URL}
              chatLoaderUrl={process.env.REACT_APP_DEFAULT_CHAT_LOADER_URL}
              chatbotConfig={chatbotConfig}
              width={width}
              height={'100%'}
            />
          </div>
        </div>
      </>
    );
  }
};

export default ChatLink;
