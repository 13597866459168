import { useEffect, useState } from 'react';

function useScreen() {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 900);
    };

    // Call handleResize initially and add event listener for resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run effect only once

  return { isMobileScreen, isTablet };
}

export default useScreen;
