export const getUserIPAddress = async () => {
  const response = await fetch('https://api.ipify.org?format=json');
  const ipAddress = await response.json();
  return ipAddress?.ip ?? '';
};

export const getCurrentRouteUrl = () => {
  // const url = window.location.pathname + window.location.search + window.location.hash;
  return document.title;
};
